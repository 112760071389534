body {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

a {
  color: white;
}

a:hover{
  color:  rgba(255, 56, 96, 1);
}

.btn.btn-primary{
  background-color: rgba(255, 56, 96, 1);
  border-color: rgba(255, 56, 96, 1);
}
.btn-primary:focus{
  box-shadow: 0 0 0 .2rem rgba(255, 56, 96, .5);
}

.btn-primary:not(:disabled):not(.disabled):active{
  background-color: rgba(255, 56, 96, 1);
  border-color: rgba(255, 56, 96, 1);
}

.nav-tabs {
  border-bottom-color: rgba(255, 56, 96, 1);
}

.nav-tabs .nav-link:hover {
  border-color: rgba(255, 56, 96, 1);
}

.nav-tabs .nav-link.active{
  background-color:rgba(255, 56, 96, 1);
  border-color: rgba(255, 56, 96, 1);
  color: white;
}

span.MuiSlider-root{
  color: rgba(255, 56, 96, 1);
}

.rv-treemap__leaf:not(:first-child){
  cursor: pointer;
  text-align: center;
}

.sliderContainer{
  margin: auto;
}

.treeMapContainer{
  margin: auto;
  margin-top: 50px;
  width: 800px;
}